import { useMediaQuery } from 'react-responsive'
import { Space } from 'antd';
import { ICardLocation } from '../../interfaces';
import Schedule from './Schedule';
import Address from './Address';
import TabAction from './TabAction';
import Button from '../main/Button';
import Map from './Map';
import Icon from '../main/Icon';
import '../styles/CardLocation.css';
import '../../utils/GlobalStyles.css';

function CardLocation({ name, base64TeamLogo, address, openingHours } : ICardLocation) 
{
    const isMobile = useMediaQuery({ query: '(width <= 700px)' });
    
    return (
        <div className = 'centered'>
            <Space direction = 'vertical' className = 'card-container shadow' size = {15}>
                <div className = 'row'>
                    <TabAction
                        icon = {<Icon icon = {'fa-regular fa-location-dot'} size = '20px'/>}
                        text = 'Location'
                    />

                    <Button
                        buttonText = 'Navigation'
                        spacing = {6}
                        rigthIcon = 
                        {
                            <Icon 
                                icon = 'fa-light fa-location-arrow' 
                                color = {sessionStorage.getItem('primaryColor') as string}
                                size = {'18px'}
                            />
                        }
                        size = 'small'
                        type = 'link'
                    />
                </div>
                
                <div 
                    className = 'row'
                    style = {isMobile ? { flexDirection: 'column' } : {}}
                >
                    <Space 
                        className = {isMobile ? 'schedule' : ''}
                        direction = 'vertical' 
                        size = {20}
                    >
                        <img src = {`data:image/png;base64, ${base64TeamLogo}`} alt = 'Brand' />

                        <Address 
                            {...{ ...address }}
                            team = {name}
                        />

                        <Schedule {...{ openingHours }} />
                    </Space>

                    <div className = 'map'>
                        <Map 
                            latitude = {+address.latitude}
                            longtitude = {+address.longtitude}
                        />
                    </div>
                </div>
            </Space>
        </div>
    )
}

export default CardLocation;