// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.shadow
{
    box-shadow: 0.8px 0.8px 5px 0px hsl(0, 0%, 0%, 0.14);
}`, "",{"version":3,"sources":["webpack://./src/utils/GlobalStyles.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;;IAEI,oDAAoD;AACxD","sourcesContent":[".centered\n{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.shadow\n{\n    box-shadow: 0.8px 0.8px 5px 0px hsl(0, 0%, 0%, 0.14);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
