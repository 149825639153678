// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/404.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found
{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center; 
    padding: 24px;
    overflow-y: hidden;
    overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/NotFound.css"],"names":[],"mappings":"AAAA;;IAEI,yDAAgD;IAChD,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".not-found\n{\n    background-image: url('../../../public/404.jpg');\n    background-size: cover;\n    background-position: center; \n    padding: 24px;\n    overflow-y: hidden;\n    overflow-x: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
