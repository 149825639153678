/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Actions } from '../components';
import CardLocation from '../components/CardLocation/CardLocation';
import ContactsList from '../components/ContactsList/ContactsList';
import NavBar from '../components/main/NavBar';
import UserProfileImage from '../components/UserProfilePicture/UserProfileImage';
import Config from '../contexts/Config';
import useFetch from '../hooks/useFetch';
import { IEmployee } from '../interfaces';
import Loading from './Loading';

function EmployeeData() 
{   
    const { urlKey } = useParams();
    const navigate = useNavigate();
    const { request, data, error } = useFetch<IEmployee>();

    useEffect(() => { request(`vcard?key=${urlKey}`) }, []);

    if (error) 
    {
        navigate('/error', { replace: true });
        return null;
    }
    if (!data) return <Loading/>

    sessionStorage.setItem('primaryColor', data.brandKey.primaryColor);
   
    return (
        <Config brandColor = {data.brandKey.primaryColor}>
            <div style = {{ backgroundColor: '#f5f5f5', height: '100%' }}>
                <NavBar logo = {data.brandKey.base64Logo}/>

                <UserProfileImage 
                    profileImage ={data.base64ProfileImage}
                    name = {data.firstName + ' ' + data.lastName}
                    role = {data.role}
                />

                <Actions employee = {data}/>

                <ContactsList
                    contacts = 
                    {{
                        email: data.email,
                        phone: data.phone,
                        mobile: data.mobile,
                        whatsapp: data.whatsapp,
                        facebook: data.facebook,
                        googleBusinessMessage: data.googleBusinessMessage,
                        website: data.website
                    }}
                />

                <CardLocation {...{ ...data.teams }}/>
            </div>
        </Config>
    )
}

export default EmployeeData;