import { ConfigProvider } from 'antd'
import { IConfig } from '../interfaces'

function Config({ children, brandColor }: IConfig) 
{
    return (
        <ConfigProvider
            theme=
            {{
                token:
                {
                    colorPrimary: brandColor || '',
                    colorLink: brandColor,
                    colorLinkHover: brandColor,
                    colorLinkActive: brandColor
                }
            }}
        >
            {children}
        </ConfigProvider>
    )
}

export default Config;