import { IUserProfileImage } from '../../interfaces';
import Text from '../main/Text';
import '../styles/UserProfileImage.css';

function UserProfileImage({ profileImage, name, role } : IUserProfileImage) 
{
    return (
        <div className = 'user-container'>
            <img src = {`data:image/png;base64, ${profileImage}`} alt = 'Profile'/>
            
            <div className = 'middle-item'>
                <Text text = {name} fontSize = {25} fontWeight = {700}/>
            </div>

            <Text text = {role} fontSize={18} fontWeight={300} />
        </div>
    )
}

export default UserProfileImage;