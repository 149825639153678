import { useState } from "react";
import instance from "../utils/AxiosConfiguration";

function useFetch<Type>() 
{
    const [data, setData] = useState<Type>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);

    async function request(url: string, method: 'get' = 'get')
    {
        setLoading(true);
        try
        {
            const data = await instance[method]<Type, Type>(url);
            setData(data);
        }
        catch (error)
        {
            setError(error);
            return false;
        }
        finally
        {
            setLoading(false);
        }
        
        return true;
    }

    return { request, data, error, loading };
}

export default useFetch