import axios, { AxiosResponse } from "axios";

const instance = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

function returnResponse(response: AxiosResponse) {
  return response.data;
}

instance.interceptors.response.use(returnResponse, null);

export default instance;
