// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px 10px 25px;
  background-color: white;
  min-height: 50px;
}

.logo {
  height: auto;
  /* max-height: 40px; */
  max-width: 300px;
}

.icon {
  transition: background-color 0.3s;
  padding: 7px;
  border-radius: 6px;
}

.icon:hover {
  background-color: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/NavBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,4BAA4B;EAC5B,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".navContainer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 10px 25px 10px 25px;\n  background-color: white;\n  min-height: 50px;\n}\n\n.logo {\n  height: auto;\n  /* max-height: 40px; */\n  max-width: 300px;\n}\n\n.icon {\n  transition: background-color 0.3s;\n  padding: 7px;\n  border-radius: 6px;\n}\n\n.icon:hover {\n  background-color: #f5f5f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
