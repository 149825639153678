import { Space } from 'antd';
import ListItem from '../ListItem/ListItem';
import { IContactList } from '../../interfaces';

const titles = 
{
    email: 'Email',
    facebook: 'Facebook Messenger',
    whatsapp: 'WhatsApp',
    phone: 'Work telephone number',
    mobile: 'Cell telephone number',
    googleBusinessMessage: 'Google Business Message',
    website: 'Website'
}

function ContactsList({ contacts } : { contacts: IContactList })
{
    return (
        <div style = {{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Space direction = 'vertical' size = {15}>
            { 
                Object.entries(contacts).map(([key, value]) => 
                {
                    if (value === '') { return null; }

                    return (
                        <ListItem
                            key = {key}
                            title = {Object(titles)[key]}
                            value = {value}
                        />
                    )
                })
            }
            </Space>
        </div>
    )
}

export default ContactsList;