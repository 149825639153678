// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img
{
    border-radius: 4px;
    width: 160px;
}

.user-container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.middle-item
{
    margin: 15px 0px 5px 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/UserProfileImage.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;;IAEI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;;IAEI,wBAAwB;AAC5B","sourcesContent":["img\n{\n    border-radius: 4px;\n    width: 160px;\n}\n\n.user-container\n{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding-top: 30px;\n}\n\n.middle-item\n{\n    margin: 15px 0px 5px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
