// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-container
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 23px 10px 16px;
    border-radius: 6px;
    width: 600px;
    min-height: 40px;
    background-color: white;
    transition: background-color 0.3s;
}

.data
{
    display: flex;
    flex-direction: column;
}

@media (width <= 700px)
{
    .data-container 
    {
        width: 330px;
    }
}

@media (width <= 400px) 
{
    .data-container 
    {
        width: 250px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/ListItem.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,4BAA4B;IAC5B,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,iCAAiC;AACrC;;AAEA;;IAEI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;;IAEI;;QAEI,YAAY;IAChB;AACJ;;AAEA;;IAEI;;QAEI,YAAY;IAChB;AACJ","sourcesContent":[".data-container\n{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 13px 23px 10px 16px;\n    border-radius: 6px;\n    width: 600px;\n    min-height: 40px;\n    background-color: white;\n    transition: background-color 0.3s;\n}\n\n.data\n{\n    display: flex;\n    flex-direction: column;\n}\n\n@media (width <= 700px)\n{\n    .data-container \n    {\n        width: 330px;\n    }\n}\n\n@media (width <= 400px) \n{\n    .data-container \n    {\n        width: 250px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
