import styled from 'styled-components';
import { IListItem } from '../../interfaces'
import '../styles/ListItem.css';
import '../../utils/GlobalStyles.css';
import Text from '../main/Text';
import Icon from '../main/Icon';

const icons = 
{
    'Cell telephone number': <Icon icon = 'fa-solid fa-phone'/>,
    'Work telephone number': <Icon icon = 'fa-solid fa-phone' />,
    'Email': <Icon icon = 'fa-solid fa-envelope' />,
    'WhatsApp': <Icon icon = 'fa-brands fa-whatsapp' size = '28px'/>,
    'Facebook Messenger': <Icon icon = 'fa-brands fa-facebook' size = '25px' />,
    'Google Business Message': <Icon icon = 'fa-brands fa-google' size = '23px'/>,
    'Website': <Icon icon = 'fa-solid fa-desktop'/>
}

function ListItem({ title, value }: IListItem) 
{
    const HoverDiv = styled.div
    `
        &:hover
        {
            background-color: ${props => props?.color}1A
        }
    `;
    
    return (
        <HoverDiv
            className = 'data-container shadow'
            color = {sessionStorage.getItem('primaryColor') as string}
        >
            <div style = {{ paddingRight: '15px'}}>
                <Text text = {title} fontSize = {16} fontWeight = {700}/>
                <Text text = {value} fontSize={16} fontWeight={300} />
            </div>

            {Object(icons)[title]}
        </HoverDiv>
    )
}

export default ListItem