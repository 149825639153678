import { Space } from 'antd';
import Text from '../components/main/Text';
import '../utils/GlobalStyles.css';
import './styles/NotFound.css';

function NotFound() 
{
    return (
        <Space 
            direction = 'vertical' 
            className = 'centered not-found' 
            size = {22}
        >
            <Text 
                text = 'OOOPS...' 
                fontSize = {28} 
                color = 'white' 
                fontWeight = {700}
                letterSpacing = '0.35px'
            />

            <Text 
                text = 'Unfortunately, the vCard you were looking for could not be found.'
                fontSize = {20} 
                lineHeight = '28px'
                color = 'white'
                letterSpacing = '0.35px'
            />
        </Space>
    )
}

export default NotFound;