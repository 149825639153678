import EmployeeData from './pages/EmployeeData';
import NotFound from './pages/NotFound';
import Welcome from './pages/Welcome';
import { Route, Routes } from 'react-router-dom';

function App() 
{
    return (
        <Routes>
            <Route path = '/' >
                <Route index element = {<Welcome/>}/>
                <Route path = ':urlKey' element = {<EmployeeData/> as JSX.Element}/>
            </Route>
            <Route path = '/error' element = {<NotFound/>}/>
        </Routes>
    )
}

export default App;
