// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container
{
    margin: 20px 0px 20px 0px;
    width: 600px;
    padding: 20px;
    background-color: white;
    border-radius: 6px;
}

.map
{
    width: 340px;
    height: 240px;
}

.logo
{
    width: 170px;
    height: 50px;
}

.row
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (width <= 700px)
{
    .card-container
    {
        width: 330px;
    }

    .map
    {
        margin-top: 20px;
        width: 100%;
        height: 174px;
    }

    .schedule
    {
        align-self: start;
    }
}

@media (width <= 400px) 
{
    .card-container 
    {
        width: 250px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/CardLocation.css"],"names":[],"mappings":"AAAA;;IAEI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;;IAEI,YAAY;IACZ,aAAa;AACjB;;AAEA;;IAEI,YAAY;IACZ,YAAY;AAChB;;AAEA;;IAEI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;;IAEI;;QAEI,YAAY;IAChB;;IAEA;;QAEI,gBAAgB;QAChB,WAAW;QACX,aAAa;IACjB;;IAEA;;QAEI,iBAAiB;IACrB;AACJ;;AAEA;;IAEI;;QAEI,YAAY;IAChB;AACJ","sourcesContent":[".card-container\n{\n    margin: 20px 0px 20px 0px;\n    width: 600px;\n    padding: 20px;\n    background-color: white;\n    border-radius: 6px;\n}\n\n.map\n{\n    width: 340px;\n    height: 240px;\n}\n\n.logo\n{\n    width: 170px;\n    height: 50px;\n}\n\n.row\n{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n@media (width <= 700px)\n{\n    .card-container\n    {\n        width: 330px;\n    }\n\n    .map\n    {\n        margin-top: 20px;\n        width: 100%;\n        height: 174px;\n    }\n\n    .schedule\n    {\n        align-self: start;\n    }\n}\n\n@media (width <= 400px) \n{\n    .card-container \n    {\n        width: 250px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
