// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container
{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

@media (width <=700px) 
{
    .container 
    {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Actions.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;;IAEI;;QAEI,sBAAsB;IAC1B;AACJ","sourcesContent":[".container\n{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 20px;\n}\n\n@media (width <=700px) \n{\n    .container \n    {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
