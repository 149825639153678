// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text 
{
    font-family: 'Nunito Sans', sans-serif;
    letter-spacing: 0.28px;
    color: #4A4D4E;
    word-break: break-word;
}

.font-700
{
    font-weight: 700;
}

.font-300
{
    font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Text.css"],"names":[],"mappings":"AAAA;;IAEI,sCAAsC;IACtC,sBAAsB;IACtB,cAAc;IACd,sBAAsB;AAC1B;;AAEA;;IAEI,gBAAgB;AACpB;;AAEA;;IAEI,gBAAgB;AACpB","sourcesContent":[".text \n{\n    font-family: 'Nunito Sans', sans-serif;\n    letter-spacing: 0.28px;\n    color: #4A4D4E;\n    word-break: break-word;\n}\n\n.font-700\n{\n    font-weight: 700;\n}\n\n.font-300\n{\n    font-weight: 300;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
