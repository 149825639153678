import { useMediaQuery } from "react-responsive";
import Button from "../main/Button";
import "../styles/Actions.css";
import { Space } from "antd";
import { saveAs } from "file-saver";
import { IEmployee } from "../../interfaces";
import Icon from "../main/Icon";

function Actions({ employee }: { employee: IEmployee }) {
  const isMobile = useMediaQuery({ query: "(width <= 700px)" });

  const addVersion = () => `\nVERSION:3.0`;
  const addName = (employee: IEmployee) =>
    `\nN:${employee.lastName};${employee.firstName};`;
  const addFormatedName = (name: string) => `\nFN:${name}`;
  const addOrganization = (org: string) => `\nORG:${org}`;
  const addTitle = (title: string) => `\nTITLE:${title}`;
  const addWorkPhone = (phone: string) => `\nTEL;type=WORK;VOICE:${phone}`;
  const addCellPhone = (phone: string) => `\nTEL;type=CELL;MOBILE:${phone}`;
  const addAddress = (address: string) =>
    `\nADR;type=WORK;type=pref:;;${address}`;
  const addGeolocation = (lat: string, lng: string) =>
    `\nGEO:geo:${lat}\\,${lng}`;
  const addPhoto = (photo: string) =>
    `\nPHOTO;TYPE=JPEG;ENCODING=BASE64:${photo}`;
  const addEmail = (email: string) => `\nEMAIL:${email}`;
  const addTime = () => `\nREV:${new Date().toISOString()}`;
  const addURL = (url: string) => `\nURL:${url}`;
  const addSocialProfle = (type: string, value: string) =>
    `\nX-SOCIALPROFILE;TYPE=${type}:${value}`;

  function makeVCard() {
    let vcard =
      "BEGIN:VCARD" +
      addVersion() +
      addName(employee) +
      addFormatedName(employee.firstName + " " + employee.lastName) +
      addOrganization(employee.brandKey.brandName) +
      addTitle(employee.role) +
      addWorkPhone(employee.phone) +
      addCellPhone(employee.mobile) +
      addAddress(
        employee.teams.address.street +
          " " +
          employee.teams.address.houseNumber +
          ";" +
          employee.teams.address.city +
          ";;" +
          employee.teams.address.zip
      ) +
      addEmail(employee.email) +
      addURL(employee.website) +
      addPhoto(employee.base64ProfileImage) +
      addGeolocation(
        employee.teams.address.latitude,
        employee.teams.address.longtitude
      ) +
      addSocialProfle("facebook", employee.facebook) +
      addSocialProfle("googlebusiness", employee.googleBusinessMessage) +
      addTime() +
      "\nEND:VCARD";

    const blob = new Blob([vcard], { type: "text/vcard" });
    saveAs(blob, employee.urlKey + ".vcf");
  }

  return (
    <Space className="container" size={isMobile ? 10 : 15}>
      <Button
        buttonText="Save contact"
        rigthIcon={
          <Icon icon="fa-light fa-download" color="white" size="19px" />
        }
        onClick={() => makeVCard()}
        size="large"
      />

      {/* <Button
                buttonText = 'Arrange meeting'
                rigthIcon = {<Icon icon = 'fa-light fa-calendar' color = 'white' size = '19px'/>}
                size = 'large'
    />*/}
      <div id="od_widget_appointment"> </div>
    </Space>
  );
}

export default Actions;
