import { Space } from 'antd';
import Text from '../main/Text';
import { IDay } from '../../interfaces';

function Day({ day, open, close }: IDay) 
{
    function makeTime(hour: string | undefined)
    {
        return hour?.slice(0,2) + ':' + hour?.slice(2);
    }

    return (
        <Space size = {20} style = {{ display: 'flex', justifyContent: 'space-between'}}>
            <Text text = {day} lineHeight ='20px'/>
            <Text text = {makeTime(open) +  ' - ' + makeTime(close)} lineHeight='20px' />
        </Space>
    )
}

export default Day;