import { Space } from "antd"
import Text from "../components/main/Text"
import './styles/Welcome.css';
import { useMediaQuery } from "react-responsive";

function Welcome() 
{
    const isMobile = useMediaQuery({ query: '(width <= 600px)' });

    const textProps = 
    {
        fontSize: 28, 
        lineHeight: '30px',
        letterSpacing: '0.35px',
        fontWeight: 700
    }

    return (
        <Space direction = 'vertical' className = 'welcome-container' size={36}>
            <div className = 'title'>
                <Text 
                    text = 'Your digital vCard' 
                    {...{ ...textProps }}
                /> 
            </div>

            <Space className = 'centered' size = {36} direction = {isMobile ? 'vertical' : 'horizontal'}>
                <img className = 'vcard-image image-shadow' src = './vcard.png' alt = 'vcard'/>

                <img className = 'image-shadow nfc-image' src = './nfc.png' alt = 'vcard'/>
            </Space>

            <div className = 'link'>
                <Text 
                    text = 'www.vcard.rocks' 
                    {...{ ...textProps }}
                /> 
            </div>
        </Space>
    )
}

export default Welcome