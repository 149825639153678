import { IIcon } from "../../interfaces";

function Icon({ icon, size = '22px', color } : IIcon) 
{
    return (
        <i 
            className = {icon} 
            style={{ fontSize: size, color: color || '#4A4D4E' }}
        />
    )
}

export default Icon;