import { Space } from 'antd';
import { ITabAction } from '../../interfaces';
import Text from '../main/Text';

function TabAction({ icon, text, rightIcon }: ITabAction) 
{
    return (
        <Space>
            {!rightIcon && icon}

            <Text {...{ text }} lineHeight = {'20px'} fontWeight = {700}/>

            {rightIcon && icon}
        </Space>
    )
}

export default TabAction;