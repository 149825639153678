import '../styles/NavBar.css'
import '../../utils/GlobalStyles.css'
import Icon from './Icon';

function NavBar({ logo }: { logo: string }) 
{
    return (
        <div className = 'navContainer shadow'>
            <img className = 'logo' src = {`data:image/png;base64, ${logo}`} alt = 'Logo' />

            <div className = 'icon'><Icon icon = 'fa-light fa-share-nodes' size = '25px'/></div>
        </div>
    )
}

export default NavBar;