import { Space } from "antd"
import Icon from "../components/main/Icon";
import Text from "../components/main/Text"
import '../utils/GlobalStyles.css';

function Loading() 
{
    return (
        <Space className = 'centered' size = {30}>
            <Text text = 'Loading...' fontSize = {45} />
            <Icon icon = 'fa-regular fa-spinner fa-spin-pulse' size = '45px'/>
        </Space>
    )
}

export default Loading;