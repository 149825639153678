import { Space } from 'antd';
import Text from '../main/Text';
import { IAddress } from '../../interfaces';

function Address({ team, city, street, houseNumber, zip } : IAddress) 
{
    return (
        <Space direction = 'vertical' size = {1}>
            <Text
                text = {team}
                fontSize = {16}
                fontWeight = {700}
            />

            <Text text = {street + ' ' + houseNumber} lineHeight = '20px' />

            <Text text = {zip + ' ' + city} lineHeight = '20px' />
        </Space>
    )
}

export default Address