import { MapContainer, Marker, TileLayer, ZoomControl } from "react-leaflet"
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

function Map({ latitude, longtitude }: { latitude: number, longtitude: number}) 
{
    L.Icon.Default.mergeOptions
    ({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    return (
        <MapContainer 
            style={{ width: '100%', height: '100%', borderRadius: '6px' }} 
            center = {[latitude, longtitude]} 
            zoom = {13} 
            scrollWheelZoom = {false}
            zoomControl = {false}
        >
            <TileLayer
                attribution = '&copy; <a href="https://www.openstreetmap.org/copyright"></a>'
                url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <ZoomControl position = 'bottomright'/>

            <Marker position = {[latitude, longtitude]}/>
        </MapContainer>
    ) 
}

export default Map;