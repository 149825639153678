import { Space } from 'antd';
import Text from '../main/Text';
import Day from './Day';
import { IOpeninigHour } from '../../interfaces';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function Schedule({ openingHours }: { openingHours: IOpeninigHour[]} ) 
{
    return (
        <Space direction='vertical' size = {1}>
            <Text
                text = 'Working time'
                fontSize = {16}
                fontWeight = {700}
            />

        {
            openingHours?.map(day => 
                <Day 
                    key = {days[day.close.day - 1]}
                    day = {days[day.close.day - 1]}
                    open = {day.open.time}
                    close = {day.close.time} 
                />
            )
        }
        </Space>
    )
}

export default Schedule;