import { IText } from '../../interfaces/index';
import '../styles/Text.css';

function Text({ text, fontSize = 14, fontWeight = 300, lineHeight = '24px', color, letterSpacing } : IText) 
{
    return (
        <p 
            className = {'text ' + (fontWeight === 700 ? 'font-700' : 'font-300')}
            style = {{ fontSize, lineHeight, color, letterSpacing }}
        >
            {text}
        </p>
    )
}

export default Text;