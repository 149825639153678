import IButtonProps from '../../interfaces/Button';
import { Button as ButtonAntd, Space } from 'antd'

function Button({ buttonText, rigthIcon, onClick, size, type = 'primary', spacing = 10 } : IButtonProps) 
{
	return (
		<ButtonAntd 
			{...{ size, type, onClick }}
			style = 
			{{ 
				fontFamily: 'Nunito Sans, serif', 
				fontWeight: type === 'link' ? 700  : 300, 
				letterSpacing: '0.28px'
			}}
		>
			<Space size = {spacing}>
				{buttonText}
				{rigthIcon}
			</Space>
		</ButtonAntd>
	)
}

export default Button;