// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-container
{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}

.image-shadow
{
    box-shadow: 0px 1px 12px 0px hsl(0, 0%, 0%, 0.24);
}

.title
{
  margin-top: 20px
}

.link
{
    margin-bottom: 10px;
}

.vcard-image
{
    width: auto;
    height: 450px;
    border: 8px solid #4A4D4E;
    border-radius: 20px;
}

.nfc-image
{
    width: 280px;
    height: 180px;
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/styles/Welcome.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;;IAEI,iDAAiD;AACrD;;AAEA;;EAEE;AACF;;AAEA;;IAEI,mBAAmB;AACvB;;AAEA;;IAEI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;;IAEI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".welcome-container\n{\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n    height: 100%;\n}\n\n.image-shadow\n{\n    box-shadow: 0px 1px 12px 0px hsl(0, 0%, 0%, 0.24);\n}\n\n.title\n{\n  margin-top: 20px\n}\n\n.link\n{\n    margin-bottom: 10px;\n}\n\n.vcard-image\n{\n    width: auto;\n    height: 450px;\n    border: 8px solid #4A4D4E;\n    border-radius: 20px;\n}\n\n.nfc-image\n{\n    width: 280px;\n    height: 180px;\n    border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
